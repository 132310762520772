const tokenKey = "token";
const userKey = "chat_user_id";
const idKey = "user_id";
const activeId = "active_id";
// token
export function setToken(token) {
 localStorage.setItem(tokenKey, token);
}
export function getToken() {
 return localStorage.getItem(tokenKey);
}
export function removeToken() {
 localStorage.removeItem(tokenKey);
}
// 环信userid
export function setChatUserId(info) {
 localStorage.setItem(userKey, info);
}
export function getChatUserId() {
 return localStorage.getItem(userKey);
}
export function removeChatUserId() {
 localStorage.removeItem(userKey);
}

// userid
export function setUserId(info) {
 localStorage.setItem(idKey, info);
}
export function getUserId() {
 return localStorage.getItem(idKey);
}
export function removeUserId() {
 localStorage.removeItem(idKey);
}
// activeId  聊天的
export function setActiveId(info) {
 localStorage.setItem(activeId, info);
}
export function getActiveId() {
 return localStorage.getItem(activeId);
}
export function removeActiveId() {
 localStorage.removeItem(activeId);
}
