import { login, messageVerificationCodeLogin } from "@/api/login";
import {
 getToken,
 setToken,
 removeToken,
 setChatUserId,
 setUserId,
 removeChatUserId,
 removeUserId,
 removeActiveId,
} from "@/utils/auth";
import { txRTCLogin, txRTClLogout } from "@/utils/tx";

const user = {
 state: {
  token: getToken(),
 },

 mutations: {
  SET_TOKEN: (state, token) => {
   state.token = token;
  },
 },

 actions: {
  // 登录
  Login({ commit }, userInfo) {
   return new Promise((resolve, reject) => {
    login(userInfo)
     .then((res) => {
      setToken(res.token);
      setChatUserId(res.emUser.username);
      setUserId(res.user.id);
      commit("SET_TOKEN", res.token);
      txRTCLogin(res.emUser.username);
      resolve();
     })
     .catch((error) => {
      reject(error);
     });
   });
  },
  // 其他登录
  otherLogin({ commit }, userInfo) {
   return new Promise((resolve, reject) => {
    messageVerificationCodeLogin(userInfo)
     .then((res) => {
      setToken(res.token);
      setChatUserId(res.emUser.username);
      setUserId(res.user.id);
      commit("SET_TOKEN", res.token);
      txRTCLogin(res.emUser.username);
      resolve();
     })
     .catch((error) => {
      reject(error);
     });
   });
  },

  // 退出系统
  LogOut({ commit }) {
   return new Promise((resolve, reject) => {
    commit("SET_TOKEN", "");
    removeToken();
    removeUserId();
    removeChatUserId();
    removeActiveId();
    txRTClLogout();
    resolve();
   });
  },
 },
};


export default user;
