<template>
    <div>
        <el-dialog title="编辑个人信息" :visible.sync="dialogVisible" width="600px" :close-on-click-modal="false"
            :close-on-press-escape="false" :show-close="false">
            <el-form :model="pageObj" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm">
                <el-form-item label="头像" prop="avatar">
                    <image-upload v-model="pageObj.avatar" :limit="1"></image-upload>
                </el-form-item>
                <el-form-item label="昵称" prop="nickName">
                    <el-input v-model="pageObj.nickName"></el-input>
                </el-form-item>
                <el-form-item label="医院" prop="hospitalId">
                    <el-select v-model="pageObj.hospitalId" filterable placeholder="请选择医院" @change="changeHospital">
                        <el-option v-for="item in hospital" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="pageObj.hospitalId" label="科室">
                    <el-cascader v-model="pageObj.departmentId" :options="department"
                        :props="{ value: 'id', label: 'departmentName', children: 'children' }"
                        @change="changeDepartment"></el-cascader>
                </el-form-item>
                <el-form-item label="职称" prop="titleId">
                    <el-select v-model="pageObj.titleId" placeholder="请选择职称" @change="changeTitle">
                        <el-option v-for="item in medTitle" :key="item.id" :label="item.titlName" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="执业备案" prop="isFilings">
                    <el-select v-model="pageObj.isFilings" disabled>
                        <el-option label="已认证" value="1"></el-option>
                        <el-option label="未认证" value="0">
                        </el-option>
                    </el-select>&nbsp;&nbsp;
                    <el-button v-if="pageObj.isFilings == '0'" type="primary" @click="toFilings">去认证</el-button>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { getMedDoctor, selectMedTitle, getHospital, getDepartment, updateMedDoctor, isAuth } from '@/api/login'
import { validateName } from '@/utils/validate'
export default {
    data() {
        return {
            dialogVisible: false,
            pageObj: {
                avatar: '',
                nickName: '',
                hospitalId: '',
                departmentId: '',
                titleId: '',
                isFilings: '0'
            },
            medTitle: [],
            hospital: [],
            department: [],
            rules: {
                avatar: [
                    { required: true, message: '请上传头像', trigger: 'change' }
                ],
                nickName: [
                    { required: true, message: '请输入昵称', trigger: 'blur' },
                    { validator: validateName, trigger: "blur" },
                ],
                hospitalId: [
                    { required: true, message: '请选择医院', trigger: 'change' }
                ],
                titleId: [
                    { required: true, message: '请选择职称', trigger: 'change' }
                ],
            }
        }
    },
    methods: {
        open() {
            this.dialogVisible = true
            this.getData()
        },
        getData() {
            this.getMedDoctor()
            this.selectMedTitle()
            this.getHospital()
        },
        // 医生信息
        async getMedDoctor() {
            const data = await getMedDoctor()
            this.pageObj.avatar = data.avatar
            this.pageObj.nickName = data.nickName
            this.pageObj.hospitalId = data.hospitalId ? Number(data.hospitalId) : ''
            this.pageObj.departmentId = data.departmentId ? Number(data.departmentId) : ''
            this.pageObj.titleId = data.titleId ? Number(data.titleId) : ''
            this.pageObj.name = data.name
            this.pageObj.departmentName = data.departmentName
            this.pageObj.titlName = data.titlName
            this.pageObj.isFilings = data.isFilings
            this.getDepartment('init')
        },
        // 医生职称
        async selectMedTitle() {
            this.medTitle = await selectMedTitle()
        },
        // 医院
        async getHospital() {
            this.hospital = await getHospital()
        },
        // 科室
        async getDepartment(type) {
            this.department = await getDepartment({ hospitalId: this.pageObj.hospitalId })
            // 处理科室数据
            this.setDepartment(this.department)
            // 设置回显数据
            if (type === 'init' && this.pageObj.departmentId) {
                const data = this.findDepartmentAndParents(this.pageObj.departmentId)
                this.pageObj.departmentId = data
            }
        },
        findDepartmentAndParents(departmentId) {
            const foundDepartments = [];

            const find = (departments, id) => {
                for (let dept of departments) {
                    if (dept.id === id) {
                        foundDepartments.push(dept);
                        return true;
                    }

                    if (dept.children && dept.children.length > 0) {
                        if (find(dept.children, id)) {
                            foundDepartments.push(dept);
                            return true;
                        }
                    }
                }

                return false;
            };

            find(this.department, departmentId);

            // 返回找到的所有部门
            return foundDepartments.reverse().map(item => item.id); // 反转数组以保证顺序从根部门到目标部门
        },
        // 设置科室数据
        setDepartment(department) {
            department.forEach(item => {
                if (item.children && item.children.length) {
                    this.setDepartment(item.children)
                } else {
                    item.children = null
                }
            })
        },
        // 切换医院
        changeHospital(val) {
            this.pageObj.name = this.hospital.find(item => item.id === val).name
            this.pageObj.departmentId = ''
            this.pageObj.departmentName = ''
            this.getDepartment()
        },
        // 切换科室
        changeDepartment(val) {
            this.pageObj.departmentName = this.getTreeNodeNameById(this.department, val[val.length - 1])
        },
        getTreeNodeNameById(treeData, targetId) {
            const findNode = (nodes) => {
                for (const node of nodes) {
                    if (node.id === targetId) {
                        return node.departmentName;
                    }
                    if (node.children && node.children.length > 0) {
                        const found = findNode(node.children);
                        if (found) {
                            return found;
                        }
                    }
                }
                return null;
            };

            return findNode(treeData);
        },
        // 切换职称
        changeTitle(val) {
            this.pageObj.titlName = this.medTitle.find(item => item.id === val).titlName
        },
        submit() {
            this.pageObj.departmentId = this.pageObj.departmentId ? this.pageObj.departmentId[this.pageObj.departmentId.length - 1] : ''
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    await updateMedDoctor(this.pageObj)
                    this.$message.success('修改成功')
                    this.dialogVisible = false
                } else {
                    return false;
                }
            });
        },
        // 去认证
        async toFilings() {
            const isAuthDone = await isAuth()
            if (!isAuthDone) {
                this.$message.error('请先完成个人信息认证并点击保存')
            } else {
                this.$router.push({ path: 'filings' })
            }
        }
    }
}
</script>
<style lang="scss" scoped></style>