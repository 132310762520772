<template>
    <div>
        <el-dialog title="修改密码" :visible.sync="dialogVisible" width="600px" :close-on-click-modal="false"
            :close-on-press-escape="false" :show-close="false">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm">
                <el-form-item label="原密码" prop="oldPassword">
                    <el-input v-model="ruleForm.oldPassword" show-password placeholder="请输入原密码"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="passWord">
                    <el-input v-model="ruleForm.passWord" show-password placeholder="请输入新密码"></el-input>
                </el-form-item>
                <el-form-item label="确认新密码" prop="againPassWord">
                    <el-input v-model="ruleForm.againPassWord" show-password placeholder="请再次输入新密码"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { updatePassWord } from '@/api/login'
import { validatePassWord } from '@/utils/validate';
export default {
    data() {
        return {
            dialogVisible: false,
            ruleForm: {
                phone: '',
                oldPassword: '',
                passWord: '',
                againPassWord: ''
            },

            rules: {
                oldPassword: [
                    { required: true, message: '请输入原密码', trigger: 'change' }
                ],
                passWord: [
                    { required: true, message: '请输入新密码', trigger: 'blur' },
                    { validator: validatePassWord, trigger: 'blur' }
                ],
                againPassWord: [
                    { required: true, message: '请再次输入新密码', trigger: 'change' },
                    { validator: this.validateAgainPassWord, trigger: 'blur' },
                ],
            }
        }
    },
    methods: {
        open(phone) {
            this.ruleForm.phone = phone
            this.dialogVisible = true
        },
        validateAgainPassWord(rule, value, callback) {
            if (value !== this.ruleForm.passWord) {
                callback(
                    "两次输入的密码不一致，请重新输入"
                );
            } else {
                callback()
            }
        },
        submit() {
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    await updatePassWord(this.ruleForm)
                    this.$message.success('修改成功')
                    this.$store.dispatch('LogOut').then(() => {
                        this.$router.replace({ path: '/login' })
                    })
                    this.dialogVisible = false
                } else {
                    return false;
                }
            });
        },
    }
}
</script>
<style lang="scss" scoped></style>