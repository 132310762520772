<template>
    <div class="back" @click="$router.go(-1)">
        <div class="left"><i class="el-icon-arrow-left"></i> 返回</div>
        <div class="page-title">{{ $route.meta.title }}</div>
    </div>

</template>
<script>
export default {
    data() {
        return {}
    }
}
</script>
<style lang="scss" scoped>
.back {
    display: flex;
    align-items: center;
    background-color: #fafafa;
    padding: 8px 0;
    margin-bottom: 8px;
    position: relative;

    .left {
        width: 100px;
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 8px;
    }

    .page-title {
        flex: 1;
        text-align: center;
        font-weight: bold;
    }
}
</style>