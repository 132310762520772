

import instance from "@/utils/https";


/**
 * 省市区
 * @param {Object} data
 */
export function getRegionsAll(params) {
  return instance({
    url: '/work/medRegions/getRegionsAll',
    method: 'GET',//请求方法
    params
  })
}

/**
 * 获取腾讯云签名
 * @param {Object} data
 */
export function getUserSig(params) {
  return instance({
    url: '/work/im/getUserSig',
    method: 'GET',//请求方法
    params
  })
}
