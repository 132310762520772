import instance from "@/utils/https";

/**
 * 发送验证码
 * @param {Object} data
 */
export function sendPhoneCode(params) {
  return instance({
    url: '/doctor/app/sendPhoneCode',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 注册
 * @param {Object} data
 */
export function register(data) {
  return instance({
    url: '/doctor/app/register',
    method: 'POST',//请求方法
    data
  })
}



/**
 * 重置密码
 * @param {Object} data
 */
export function resetPassWord(data) {
  return instance({
    url: '/doctor/app/resetPassWord',
    method: 'POST',//请求方法
    data
  })
}


/**
 * 账号登录
 * @param {Object} data
 */
export function login(data) {
  return instance({
    url: '/doctor/app/login',
    method: 'POST',//请求方法
    data
  })
}


/**
 * 验证码登录
 * @param {Object} data
 */
export function messageVerificationCodeLogin(data) {
  return instance({
    url: '/doctor/app/messageVerificationCodeLogin',
    method: 'POST',//请求方法
    data
  })
}


/**
 * 首页个人信息展示
 * @param {Object} data
 */
export function getDoctorInformation(params) {
  return instance({
    url: '/doctor/home/getDoctorInformation',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 接诊订单
 * @param {Object} data
 */
export function getConsultation(params) {
  return instance({
    url: '/doctor/home/getConsultation',
    method: 'GET',//请求方法
    params
  })
}
/**
 * 在线接诊
 * @param {Object} data
 */
export function receptionList(params) {
  return instance({
    url: '/doctor/home/receptionList',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 问诊题库
 * @param {Object} data
 */
export function getSubjectLibrary(params) {
  return instance({
    url: '/doctor/home/getSubjectLibrary',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 添加题库
 * @param {Object} data
 */
export function insertSubjectLibrary(data) {
  return instance({
    url: '/doctor/home/insertSubjectLibrary',
    method: 'POST',//请求方法
    data
  })
}


/**
 * 批量删除题库
 * @param {Object} data
 */
export function deleteSubjectLibrary(data) {
  return instance({
    url: '/doctor/home/deleteSubjectLibrary',
    method: 'POST',//请求方法
    data
  })
}


/**
 * 设置医生开处方
 * @param {Object} data
 */
export function setPrescription(data) {
  return instance({
    url: '/doctor/home/setPrescription',
    method: 'POST',//请求方法
    data
  })
}


/**
 * 获取医生的信息
 * @param {Object} data
 */
export function getMedDoctor(params) {
  return instance({
    url: '/doctor/mine/getMedDoctor',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 查询职称
 * @param {Object} data
 */
export function selectMedTitle(params) {
  return instance({
    url: '/doctor/mine/selectMedTitle',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 修改头像和姓名
 * @param {Object} data
 */
export function updateMedDoctor(data) {
  return instance({
    url: '/doctor/mine/updateMedDoctor',
    method: 'POST',//请求方法
    data
  })
}


/**
 * 修改头像和姓名
 * @param {Object} data
 */
export function updateBriefAndHonor(data) {
  return instance({
    url: '/doctor/home/updateBriefAndHonor',
    method: 'POST',//请求方法
    data
  })
}


/**
 * 患者分组管理
 * @param {Object} data
 */
export function getDoctorGroupList(params) {
  return instance({
    url: '/doctor/home/getDoctorGroupList',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 添加分组
 * @param {Object} data
 */
export function insertDoctorGroup(data) {
  return instance({
    url: '/doctor/home/insertDoctorGroup',
    method: 'POST',//请求方法
    data
  })
}

/**
 * 编辑分组
 * @param {Object} data
 */
export function updateDoctorGroup(data) {
  return instance({
    url: '/doctor/home/updateDoctorGroup',
    method: 'PUT',//请求方法
    data
  })
}


/**
 * 删除分组下的患者
 * @param {Object} data
 */
export function deleteUserById(params) {
  return instance({
    url: '/doctor/home/deleteUserById',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 移动患者用户分组
 * @param {Object} data
 */
export function moveUser(data) {
  return instance({
    url: '/doctor/home/moveUser',
    method: 'POST',//请求方法
    data
  })
}


/**
 * 添加题型
 * @param {Object} data
 */
export function insertTopic(data) {
  return instance({
    url: '/doctor/home/insertTopic',
    method: 'POST',//请求方法
    data
  })
}


/**
 * 查看题型
 * @param {Object} data
 */
export function selectTopic(params) {
  return instance({
    url: '/doctor/home/selectTopic',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 修改答案和题目
 * @param {Object} data
 */
export function updateTopic(data) {
  return instance({
    url: '/doctor/home/updateTopic',
    method: 'POST',//请求方法
    data
  })
}


/**
 * 挂号预约排班设置
 * @param {Object} data
 */
export function setMedDoctorReservation(data) {
  return instance({
    url: '/doctor/home/setMedDoctorReservation',
    method: 'POST',//请求方法
    data
  })
}


/**
 * 上传执业备案照
 * @param {Object} data
 */
export function saveDoctorPicture(data) {
  return instance({
    url: '/doctor/mine/saveDoctorPicture',
    method: 'POST',//请求方法
    data
  })
}


/**
 * 医生的余额明细
 * @param {Object} data
 */
export function getDoctorMoneyLog(params) {
  return instance({
    url: '/doctor/mine/getDoctorMoneyLog',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 提现记录
 * @param {Object} data
 */
export function getDoctorWithdrawalLog(params) {
  return instance({
    url: '/doctor/mine/getDoctorWithdrawalLog',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 患者评价
 * @param {Object} data
 */
export function getMedEvaluateListPage(params) {
  return instance({
    url: '/doctor/mine/getMedEvaluateListPage',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 问诊的订单
 * @param {Object} data
 */
export function getConsultation1(params) {
  return instance({
    url: '/doctor/mine/getConsultation',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 预约挂号的订单
 * @param {Object} data
 */
export function getRegister(params) {
  return instance({
    url: '/doctor/mine/getRegister',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 修改密码
 * @param {Object} data
 */
export function updatePassWord(data) {
  return instance({
    url: '/doctor/mine/updatePassWord',
    method: 'POST',//请求方法
    data
  })
}


/**
 * 更换手机号
 * @param {Object} data
 */
export function updateMedDoctorPhone(data) {
  return instance({
    url: '/doctor/mine/updateMedDoctorPhone',
    method: 'POST',//请求方法
    data
  })
}


/**
 * 隐私政策
 * @param {Object} data
 */
export function getMedAgreement(data) {
  return instance({
    url: '/doctor/mine/getMedAgreement',
    method: 'POST',//请求方法
    data
  })
}


/**
 * 获取患者最新的信息
 * @param {Object} data
 */
export function getPatientInformation(params) {
  return instance({
    url: '/doctor/home/getPatientInformation',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 处方列表
 * @param {Object} data
 */
export function getPrescription(params) {
  return instance({
    url: '/doctor/home/getPrescription',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 咨询方式
 * @param {Object} data
 */
export function getPriceConsult(data) {
  return instance({
    url: '/doctor/home/getPriceConsult',
    method: 'POST',//请求方法
    data
  })
}


/**
 * 咨询方式的开通
 * @param {Object} data
 */
export function grantPermission(params) {
  return instance({
    url: '/doctor/home/grantPermission',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 申请修改咨询的价格
 * @param {Object} data
 */
export function updateApplicationPrice(data) {
  return instance({
    url: '/doctor/home/updateApplicationPrice',
    method: 'POST',//请求方法
    data
  })
}


/**
 * 搜索的疾病
 * @param {Object} data
 */
export function likeMedCommonDiseasesByName(data) {
  return instance({
    url: '/doctor/home/likeMedCommonDiseasesByName',
    method: 'POST',//请求方法
    data
  })
}


/**
 * 搜索的疾病
 * @param {Object} data
 */
export function getPrescriptionLogPage(params) {
  return instance({
    url: '/doctor/mine/getPrescriptionLogPage',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 搜索医生擅长的疾病添加
 * @param {Object} data
 */
export function insertSpecialise(data) {
  return instance({
    url: '/doctor/home/insertSpecialise',
    method: 'POST',//请求方法
    data
  })
}


/**
 * 搜索的疾病
 * @param {Object} data
 */
export function deleteSpecialiseByDiseasesId(params) {
  return instance({
    url: '/doctor/home/deleteSpecialiseByDiseasesId',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 获取所有医院
 * @param {Object} data
 */
export function getHospital(params) {
  return instance({
    url: '/doctor/mine/getHospital',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 分科室
 * @param {Object} data
 */
export function getDepartment(data) {
  return instance({
    url: '/doctor/mine/getDepartment',
    method: 'POST',//请求方法
    data
  })
}


/**
 * 聊天会话列表
 * @param {Object} data
 */
export function getMedChatList(params) {
  return instance({
    url: '/doctor/chat/getMedChatList',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 接诊
 * @param {Object} data
 */
export function doctorJoin(params) {
  return instance({
    url: '/doctor/home/doctorJoin',
    method: 'GET',//请求方法
    params
  })
}

/**
 * 保存会话
 * @param {Object} data
 */
export function saveChat(params) {
  return instance({
    url: '/doctor/chat/saveChat',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 获取聊天记录
 * @param {Object} data
 */
export function getChatList(params) {
  return instance({
    url: '/doctor/chat/getChatList',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 问诊表
 * @param {Object} data
 */
export function getInquiry(params) {
  return instance({
    url: '/doctor/chat/getInquiry',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 查看用户回答的问题
 * @param {Object} data
 */
export function selectAnswer(params) {
  return instance({
    url: '/doctor/chat/selectAnswer',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 诊疗记录
 * @param {Object} data
 */
export function getZlRecord(params) {
  return instance({
    url: '/doctor/chat/getZlRecord',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 医生回调改成成消息已读
 * @param {Object} data
 */
export function redMessage(params) {
  return instance({
    url: '/doctor/chat/redMessage',
    method: 'GET',//请求方法
    params
  })
}



/**
 * 未读消息
 * @param {Object} data
 */
export function unreadMessage(params) {
  return instance({
    url: '/doctor/chat/unreadMessage',
    method: 'GET',//请求方法
    params
  })
}	


/**
 * 视频通话
 * @param {Object} data
 */
export function video(params) {
  return instance({
    url: '/doctor/chat/video',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 语音通话
 * @param {Object} data
 */
export function voice(params) {
  return instance({
    url: '/doctor/chat/voice',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 判断是否认证信息是否填充完整
 * @param {Object} data
 */
export function isAuth(params) {
  return instance({
    url: '/doctor/mine/isAuth',
    method: 'GET',//请求方法
    params
  })
}



/**
 * 判断是否认证信息是否填充完整
 * @param {Object} data
 */
export function searchUserGroup(params) {
  return instance({
    url: '/doctor/home/searchUserGroup',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 判断是否认证信息是否填充完整
 * @param {Object} data
 */
export function getPrescriptionId(params) {
  return instance({
    url: '/doctor/home/getPrescriptionId',
    method: 'GET',//请求方法
    params
  })
}


/**
 * 获取图片验证码
 * @param {Object} data
 */
export function captchaImage(params) {
  return instance({
    url: '/doctor/app/captchaImage',
    method: 'GET',//请求方法
    params
  })
}