<template>
  <el-container>
    <el-aside width="260px">
      <div class="top" @click="toMy">
        <img :src="userInfo.avatar" class="avatar" alt="">
        <div class="nick-name">{{ userInfo.nickName }}</div>
      </div>
      <div class="bottom">
        <sidebar></sidebar>
      </div>
    </el-aside>
    <el-container>
      <el-header>
        <img src="@/assets/img/title.png" class="logo" alt="" @click="toHome">
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            欢迎您，{{ userInfo.nickName }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown" class="dropdown">
            <el-dropdown-item>
              <div class="out" @click="changeUserInfo">个人信息</div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="out" @click="changePassWord">修改密码</div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="out" @click="changePhone">绑定手机</div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="out" @click="layOut">退出登录</div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-header>
      <el-main>
        <back v-if="$route.meta.showTitle != false"></back>
        <div class="container">
          <router-view></router-view>
        </div>
      </el-main>
    </el-container>
    <userInfo ref="userInfo"></userInfo>
    <changePassword ref="changePassword"></changePassword>
    <changePhone ref="changePhone"></changePhone>
  </el-container>
</template>
<script>
import { getDoctorInformation } from '@/api/login';
import sidebar from './components/sidebar/index'
import back from './components/back/index'
import userInfo from '@/components/userInfo/index'
import changePassword from '@/components/changePassword/index'
import changePhone from '@/components/changePhone/index'
export default {
  components: { sidebar, back ,userInfo,changePassword,changePhone},
  data() {
    return {
      active: '/home',
      userInfo: {},
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    // 获取用户信息
    async getInfo() {
      this.userInfo = await getDoctorInformation()
    },
    // 退出登录
    layOut() {
      // removeToken()
      // removeUserId()
      // removeChatUserId()
      // WebIM.conn.close()
      // txRTClLogout()
      this.$store.dispatch('LogOut').then(() => {
        this.$router.replace({ path: '/login' })
      })
    },
    toHome() {
      this.$router.push({ path: '/' })
    },
    toMy() {
      this.$router.push({ path: '/my' })
    },
    goBack() {
      this.$router.go(-1)
    },
    changePassWord() {
      this.$refs.changePassword.open(this.userInfo.phone)
    },
    changePhone() {
      this.$refs.changePhone.open(this.userInfo.phone)
    },
    changeUserInfo() {
      this.$refs.userInfo.open()
    }
  }
}
</script>
<style lang="scss" scoped>
.el-container {
  height: 100%;

  .el-aside {
    border-right: 1px solid #bfbfbf;

    .top {
      height: 260px;
      border-bottom: 1px solid #bfbfbf;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      .avatar {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }

      .nick-name {
        padding-top: 16px;
        font-size: 18px;
        color: #000;
      }
    }

    .bottom {}
  }

  .el-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #bfbfbf;

    .logo {
      height: 40px;
      cursor: pointer;
    }

    .el-dropdown {
      cursor: pointer;
    }

    .dropdown {
      .el-dropdown-menu__item {
        padding: 0;

        .out {
          padding: 0 20px;
        }
      }
    }
  }

  .el-main {
    position: relative;

    .container {
      height: calc(100vh - 146px);
      overflow-y: auto;
    }
  }
}
</style>