
class Timer {
 constructor(duration) {
  this.remainingSeconds = duration;
  this.intervalId = null;
 }
 start(callBack) {
  this.intervalId = setInterval(async () => {
    console.log('this.remainingSeconds',this.remainingSeconds);
    
   if (this.remainingSeconds > 0) {
    this.remainingSeconds--;
   } else {
    this.stop();
    callBack && callBack(0);
    console.log("结束啦--------------");
   }
  }, 1000);
 }

 stop() {
  this.intervalId && clearInterval(this.intervalId);
  this.intervalId = null;
  this.remainingSeconds = 0;
 }
}

export default Timer;
