import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
import Layout from "@/layout";
export const constantRoutes = [
 {
  path: "/login",
  name: "login",
  component: () => import("@/views/login"),
  hidden: true,
 },
 {
  path: "/register",
  name: "register",
  component: () => import("@/views/login/register"),
  hidden: true,
 },
 {
  path: "/otherLogin",
  name: "otherLogin",
  component: () => import("@/views/login/otherLogin"),
  hidden: true,
 },
 {
  path: "/forget",
  name: "forget",
  component: () => import("@/views/login/forget"),
  hidden: true,
 },
 {
  path: "/",
  component: Layout,
  redirect: "home",
  children: [
   {
    path: "home",
    component: () => import("@/views/home/index"),
    name: "home",
    meta: { title: "首页" , showTitle: false},
   },
  ],
 },
];
export const asyncRouterMap = [
 {
  path: "/onlineConsultation",
  component: Layout,
  name: 'onlineConsultationFather',
  redirect: "onlineConsultation/index",
  meta: { title: "在线接诊", img: require("@/assets/img/home/wz.png"),showBadge: false },
  children: [
   {
    path: "index",
    component: () => import("@/views/onlineConsultation/index"),
    name: "onlineConsultation",
    meta: { title: "在线接诊" },
   },
  ],
 },
 {
  path: "/patientManagement",
  component: Layout,
  redirect: "patientManagement/index",
  meta: { title: "患者管理", img: require("@/assets/img/home/hz.png") },
  children: [
   {
    path: "index",
    component: () => import("@/views/patientManagement/index"),
    name: "patientManagement",
    meta: { title: "患者管理" },
   },
   {
    path: "patientInfo",
    component: () => import("@/views/patientManagement/patientInfo"),
    name: "patientInfo",
    meta: { title: "患者详情" },
   },
   {
    path: "prescriptionDetails",
    component: () => import("@/views/patientManagement/prescriptionDetails"),
    name: "prescriptionDetails",
    meta: { title: "处方详情" },
   },
  ],
 },
 {
  path: "/questionnaire",
  component: Layout,
  redirect: "questionnaire/index",
  meta: { title: "问诊表", img: require("@/assets/img/home/wzb.png") },
  children: [
   {
    path: "index",
    component: () => import("@/views/questionnaire/index"),
    name: "questionnaire",
    meta: { title: "问诊表" },
   },
  ],
 },
 {
  path: "/imgChat",
  component: Layout,
  redirect: "imgChat/index",
  meta: { title: "图文咨询", img: require("@/assets/img/home/tw.png") },
  children: [
   {
    path: "index",
    component: () => import("@/views/imgChat/index"),
    name: "imgChat",
    meta: { title: "图文咨询" },
   },
  ],
 },
 {
  path: "/phoneChat",
  component: Layout,
  redirect: "phoneChat/index",
  meta: { title: "电话咨询", img: require("@/assets/img/home/dh.png") },
  children: [
   {
    path: "index",
    component: () => import("@/views/phoneChat/index"),
    name: "phoneChat",
    meta: { title: "电话咨询" },
   },
  ],
 },
 {
  path: "/videoChat",
  component: Layout,
  redirect: "videoChat/index",
  meta: { title: "视频咨询", img: require("@/assets/img/home/sp.png") },
  children: [
   {
    path: "index",
    component: () => import("@/views/videoChat/index"),
    name: "videoChat",
    meta: { title: "视频咨询" },
   },
  ],
 },
//  {
//   path: "/registration",
//   component: Layout,
//   redirect: "registration/index",
//   meta: { title: "挂号预约", img: require("@/assets/img/home/gh.png") },
//   children: [
//    {
//     path: "index",
//     component: () => import("@/views/registration/index"),
//     name: "registration",
//     meta: { title: "挂号预约" },
//    },
//   ],
//  },
 {
  path: "/makePrescription",
  component: Layout,
  redirect: "makePrescription/index",
  meta: { title: "开具处方", img: require("@/assets/img/home/cf.png") },
  children: [
   {
    path: "index",
    component: () => import("@/views/makePrescription/index"),
    name: "makePrescription",
    meta: { title: "开具处方" },
   },
  ],
 },
 {
  path: "/message",
  component: Layout,
  redirect: "message/index",
  name: 'messageFather',
  meta: { title: "患者消息", img: require("@/assets/img/home/message.png"),showBadge: false },
  children: [
   {
    path: "index",
    component: () => import("@/views/message/index"),
    name: "message",
    meta: { title: "患者消息" },
   },
  ],
 },
 {
  path: "/my",
  component: Layout,
  redirect: "my/index",
  meta: { title: "我的", img: require("@/assets/img/home/my.png") },
  children: [
   {
    path: "index",
    component: () => import("@/views/my/index"),
    name: "my",
    meta: { title: "我的" },
   },
   {
    path: "filings",
    component: () => import("@/views/my/filings"),
    name: "filings",
    meta: { title: "执业备案" },
   },
   {
    path: "incomeDetails",
    component: () => import("@/views/my/incomeDetails"),
    name: "incomeDetails",
    meta: { title: "收支明细" },
   },
   {
    path: "withdrawLog",
    component: () => import("@/views/my/withdrawLog"),
    name: "withdrawLog",
    meta: { title: "提现记录" },
   },
   {
    path: "evaluate",
    component: () => import("@/views/my/evaluate"),
    name: "evaluate",
    meta: { title: "患者评价" },
   },
   {
    path: "prescription",
    component: () => import("@/views/my/prescription"),
    name: "prescription",
    meta: { title: "处方档案" },
   },
   {
    path: "invite",
    component: () => import("@/views/my/invite"),
    name: "invite",
    meta: { title: "邀请有奖" },
   },
   {
    path: "setting",
    component: () => import("@/views/my/setting"),
    name: "setting",
    meta: { title: "设置" },
   },
   {
    path: "service",
    component: () => import("@/views/my/service"),
    name: "service",
    meta: { title: "客服中心" },
   },
   {
    path: "order",
    component: () => import("@/views/my/order"),
    name: "order",
    meta: { title: "我的订单" },
   },
   {
    path: "information",
    component: () => import("@/views/my/information"),
    name: "information",
    meta: { title: "给患者看的信息" },
   },
  ],
 },
];

// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
 return routerPush.call(this, location).catch((err) => err);
};
const router = new VueRouter({
 mode: "",
 scrollBehavior: () => ({ y: 0 }),
 routes: constantRoutes.concat(asyncRouterMap),
});

export default router;
