import router from "@/router";
import { getToken } from "@/utils/auth";
const whiteList = ["login", "register", "otherLogin", "forget"] ;
router.beforeEach((to, from, next) => {
 if (to.meta.title) {
  document.title = to.meta.title;
 }
 if (getToken() || whiteList.includes(to.name)) {
    next();
  } else {
    // 否则重定向到登录页
    next("/login");
  }
});
