<template>
    <svg
      :class="svgClass"
      :style="{ color: color, width: width, height: height }"
      aria-hidden="true"
    >
      <use :xlink:href="`#${iconName}`" />
    </svg>
  </template>
  
  <script>
  export default {
    name: 'SvgIcon',
    props: {
      iconClass: {
        type: String,
        required: true
      },
      color: {
        type: String,
        default: 'currentColor'
      },
      width: {
        type: [String, Number],
        default: '1em'
      },
      height: {
        type: [String, Number],
        default: '1em'
      }
    },
    computed: {
      iconName() {
        return this.iconClass;
      },
      svgClass() {
        return `svg-icon-${this.iconClass}`;
      }
    }
  };
  </script>
  
  <style scoped>
  .svg-icon {
    fill: currentColor;
    display: inline-block;
  }

  </style>