import instance from "@/utils/https";


// 医生是否开启接诊
export function doctorSetState(params) {
    return instance({
      url: '/doctor/home/setState',
      method: 'GET',//请求方法
      params
    })
  }
  
// 开处方
export function makePrescription(data) {
    return instance({
      url: '/doctor/home/makePrescription',
      method: 'POST',//请求方法
      data
    })
  }
  // 处方详情
export function getMedprescriptionById(params) {
    return instance({
      url: '/doctor/mine/getMedprescriptionById',
      method: 'GET',//请求方法
      params
    })
  }
/**
 * 问诊是否过期
 * @param {Object} data
 */
export function chatTimeOut(id) {
  return instance({
    url: '/doctor/chat/chatTime/' + id,
    method: 'GET',//请求方法
  })
}
/**
 * 聊天已读
 * @param {Object} data
 */
export function readMessage(params) {
  return instance({
    url: '/doctor/chat/readMessage',
    method: 'GET',//请求方法
    params
  })
}
/**
 * 结束问诊
 */
export function initiativeEndTime(id) {
  return instance({
    url: '/doctor/chat/initiativeEndTime/' + id,
    method: 'PUT',//请求方法
  })
}
/**
 * 提现
 */
export function transfer(data) {
  return instance({
    url: '/doctor/mine/transfer',
    method: 'POST',//请求方法
    data
  })
}

/**
 * 获取开处方药品
 */
export function getMedicinesPage(params) {
  return instance({
    url: '/doctor/chat/getMedicinesPage',
    method: 'get',//请求方法
    params
  })
}
/**
 * 聊天详情
 */
export function doctorChatDetails(id) {
  return instance({
    url: '/doctor/chat/' +id,
    method: 'GET',//请求方法
  })
}