import { getGuid } from "@/utils/common";
const user = {
 state: {
  // 音视频聊天信息
  vuex_chat_Info: {},
  // 更新音视频聊天记录
  vuex_update_chat_video: getGuid(),
  // 更新聊天记录以及获取回话列表
  vuex_update_chat: {},
  // 付费倒计时等
  vuex_update_chat_pocture: getGuid(),
  // 有问诊消息
  vuex_update_chat_reception: {},
 },

 mutations: {
  SET_VUEX_CHAT_INFO: (state, data) => {
   state.vuex_chat_Info = data;
  },
  SET_VUEX_UPDATE_CHAT: (state, data) => {
   state.vuex_update_chat = data;
  },
  SET_VUEX_UPDATE_CHAT_VIDEO: (state, data) => {
   state.vuex_update_chat_video = data;
  },
  SET_VUEX_UPDATE_CHAT_POCTURE: (state, data) => {
   state.vuex_update_chat_pocture = data;
  },
  SET_VUEX_UPDARE_CHAT_RECEPTION: (state, data) => {
   state.vuex_update_chat_reception = data;
  },
 },

 actions: {
  set_vuex_chat_Info({ commit }, data) {
   commit("SET_VUEX_CHAT_INFO", data);
  },
  set_vuex_update_chat({ commit }, data) {
   commit("SET_VUEX_UPDATE_CHAT", data);
  },
  set_vuex_update_chat_video({ commit }, data) {
   commit("SET_VUEX_UPDATE_CHAT_VIDEO", data);
  },
  set_vuex_update_chat_pocture({ commit }, data) {
   commit("SET_VUEX_UPDATE_CHAT_POCTURE", data);
  },
  set_vuex_update_chat_reception({ commit }, data) {
   commit("SET_VUEX_UPDARE_CHAT_RECEPTION", data);
  },
 },
};

export default user;
