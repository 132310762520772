
// 密码
export function validatePassWord(rule, value, callback) {
 const regex =
  /^(?=.*[a-zA-Z])(?=.*\d)(?=(.*[^a-zA-Z\d\s]))[A-Za-z\d\S]{6,20}$/;
 if (regex.test(value)) {
  callback();
 } else {
  callback(
   "密码必须是6-20个字符之间，至少要包含英文字母、数字、特殊符号(除空格)"
  );
 }
}
// 输入中文 数字 英文 
export function validateName(rule, value, callback) {
 const regex =
  /^[\u4e00-\u9fa5A-Za-z0-9]*$/;
 if (regex.test(value)) {
  callback();
 } else {
  callback(
   "只能输入汉字、数字和英文字母"
  );
 }
}

// 检测有没有摄像头和麦克风
export async function checkDevice() {
 try {
  const mediaStreamConstraints = {
   video: true,
   audio: true,
  };
  const stream = await navigator.mediaDevices.getUserMedia(
   mediaStreamConstraints
  );
  stream.getTracks().forEach((track) => track.stop());
  return true;
 } catch (error) {
  return false;
 }
}
