import { TUICallKitServer } from "@tencentcloud/call-uikit-vue2";
import { TUICallEvent } from "tuicall-engine-webrtc";
import TIM from "@tencentcloud/chat";
import { getUserSig } from "@/api/work";
import { getMedDoctor, voice, video } from "@/api/login";
import { getGuid } from "./common";
import store from "@/store";
import { getChatUserId } from "./auth";

import TencentCloudChat from "@tencentcloud/chat";
import TIMUploadPlugin from "tim-upload-plugin";

const filePath = require("@/assets/img/bgm.mp3");

const options = {
 SDKAppID: 1600051336,
};
// 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
let chat = TencentCloudChat.create(options); // SDK 实例通常用 chat 表示

let tim;
// 登录
async function txRTCLogin(userId) {
 // 注册腾讯云即时通信 IM 上传插件
 chat.registerPlugin({ "tim-upload-plugin": TIMUploadPlugin });
 chat.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
 const userSig = await getUserSig({ rtcId: userId });
 const SDKAppID = options.SDKAppID;
 const loginParams = {
  SDKAppID,
  userID:userId,
  userSig,
 };
 chat
  .login(loginParams)
  .then((imResponse) => {
   console.log("腾讯云IM登录成功", imResponse.data); // 登录成功
   if (imResponse.data.repeatLogin === true) {
    // 标识账号已登录，本次登录操作为重复登录。
    console.log(
     "标识账号已登录，本次登录操作为重复登录",
     imResponse.data.errorInfo
    );
   }
  })
  .catch(function (imError) {
   console.warn("login error:", imError); // 登录失败的相关信息
  });

 tim = TIM.create({
  SDKAppID: SDKAppID,
 });

 await TUICallKitServer.init({
  userID: userId,
  userSig,
  SDKAppID,
  tim,
 });
 const data = await getMedDoctor();
 console.log("data", data);
 // 设置头像
 await TUICallKitServer.setSelfInfo({
  nickName: data.nickName,
  avatar: data.avatar,
 });
 // 设置悬浮窗
 TUICallKitServer.enableFloatWindow(true);
 // 设置铃声
 await TUICallKitServer.setCallingBell(filePath);
 // 监听
 handleListener();
}

// 手动挂断
async function hangUp() {
 await TUICallKitServer.hangup();
}
// 退出腾讯云
async function txRTClLogout() {
 hangUp();
 chat.logout();
 setTimeout(() => {
  TUICallKitServer.destroyed();
  tim.logout();
 }, 1000);
}
let isUserOperation = false;
function handleListener() {
 const eventListeners = [
  {
   eventName: TUICallEvent.USER_ACCEPT,
   callback: (res) => {
    console.log("xxxx 用户接通的事件", JSON.stringify(res));
   },
  },
  {
   eventName: TUICallEvent.USER_ENTER,
   callback: (res) => {
    console.log("xxxx 用户加入通话的事件", JSON.stringify(res));
   },
  },
  {
   eventName: TUICallEvent.USER_LEAVE,
   callback: (res) => {
    console.log("xxxx 用户离开通话的事件", JSON.stringify(res));
   },
  },
  {
   eventName: TUICallEvent.REJECT,
   callback: async (res) => {
    console.log("xxxx 用户拒绝通话的事件", JSON.stringify(res));
    isUserOperation = true;
    await setVideoData(1);
    console.log("---------------------------2");
    setTimeout(() => {
      store.dispatch("set_vuex_update_chat_video", getGuid());
     }, 1000);
   },
  },
  {
   eventName: TUICallEvent.NO_RESP,
   callback: async (res) => {
    console.log("xxxx 用户不响应的事件", JSON.stringify(res));

    isUserOperation = true;
    await setVideoData(4);
    setTimeout(() => {
      store.dispatch("set_vuex_update_chat_video", getGuid());
     }, 1000);
   },
  },
  {
   eventName: TUICallEvent.LINE_BUSY,
   callback: async (res) => {
    console.log("xxxx 用户忙线的事件", JSON.stringify(res));
    isUserOperation = true;
    await setVideoData(5);
    setTimeout(() => {
      store.dispatch("set_vuex_update_chat_video", getGuid());
     }, 1000);
   },
  },
  {
   eventName: TUICallEvent.ON_CALL_CANCELED,
   callback: async (res) => {
    console.log("通话取消的事件", JSON.stringify(res));
    console.log(
     "---------------------------3",
     isUserOperation,
     getChatUserId()
    );

    if (!isUserOperation && res.reason == "normal") {
     await setVideoData(0);
    }
    setTimeout(() => {
     store.dispatch("set_vuex_update_chat_video", getGuid());
    }, 1000);
   },
  },
  {
   eventName: TUICallEvent.CALLING_END,
   callback: async (res) => {
    console.log("通话结束的事件", JSON.stringify(res));
    await setVideoData(3);
    setTimeout(() => {
      store.dispatch("set_vuex_update_chat_video", getGuid());
     }, 1000);
   },
  },
  {
   eventName: TUICallEvent.ERROR,
   callback: (res) => {
    console.log("错误错误", JSON.stringify(res));
   },
  },
  {
   eventName: TUICallEvent.SDK_READY,
   callback: (res) => {
    console.log("登录成功后", JSON.stringify(res));
   },
  },
 ];
 eventListeners.forEach(({ eventName, callback }) => {
  TUICallKitServer.getTUICallEngineInstance().on(eventName, callback);
 });
}
async function setVideoData(answerStatus) {
 console.log("store.state", store.state);

 const vuex_chat_Info = store.state.IM.vuex_chat_Info;
 console.log("======1", vuex_chat_Info);
 const request = vuex_chat_Info.callMediaType == 1 ? voice : video;
 if (vuex_chat_Info.chatId) {
  // type: 用户1 医生0
  console.log("======2");
  await request({ chatId: vuex_chat_Info.chatId, answerStatus, type: 0 });
  console.log("---------------------------1");
  setTimeout(() => {
   isUserOperation = false;
  }, 500);
 } else {
  setTimeout(() => {
   isUserOperation = false;
  }, 500);
 }
}
export { txRTCLogin, hangUp, txRTClLogout,chat };
