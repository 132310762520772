<template>
  <div id="app">
    <router-view />
    <audio ref="audio" style="display: none;" src="./assets/img/13203.wav" autoplay="autoplay"></audio>
    <div v-draggable class="call-container">
      <TUICallKit style="width: 650px; height: 500px">
      </TUICallKit>
    </div>
  </div>
</template>
<script>
import { getToken, getChatUserId } from './utils/auth';
import { getGuid } from './utils/common';
import { asyncRouterMap } from '@/router'
import { unreadMessage } from './api/login';
import { TUICallKit } from "@tencentcloud/call-uikit-vue2";
import { txRTCLogin } from '@/utils/tx'
import draggable from '@/utils/directive';
import { checkDevice } from "@/utils/validate";
import { chatTimeOut, doctorChatDetails } from '@/api/doctor.js'
import Timer from '@/utils/countDown.js'
export default {
  components: { TUICallKit },
  directives: {
    draggable
  },
  data() {
    return {
      asyncRouterMap: asyncRouterMap,
      chatId: '',
    }
  },
  mounted() {
    if (getToken()) {
      // 腾讯云登录
      txRTCLogin(getChatUserId())
      // 开启监听
      this.$chat.on(this.$TencentCloudChat.EVENT.MESSAGE_RECEIVED, this.onMessageReceived);
      // 多端登录监听
      this.$chat.on(this.$TencentCloudChat.EVENT.KICKED_OUT, this.onKickedOut);
      try {
        // 检测摄像头麦克风
        this.checkDevices()
      } catch (error) {

      }
    }
  },
  methods: {
    async unreadMessage() {
      const data = await unreadMessage()
      if (data && this.$route.name !== 'message') {
        this.asyncRouterMap.forEach(item => {
          if (item.name === 'messageFather') {
            item.meta.showBadge = true
          }
        })
      }
    },
    async checkDevices() {
      const device = await checkDevice()
      if (!device) {
        this.$notify({
          title: '提示',
          message: '检测到您的摄像头/麦克风未开启，请开启后进入我的-设置-检测设备中再次检测',
          duration: 0
        });
      }
    },
    // 获取问诊是否过期
    async getChatTimeOut() {
      const res = await chatTimeOut(this.chatId)
      this.isTimeOut = res.data ? false : true
      this.setTimer(res.data)
    },
    // 设置剩余时间
    setTimer(time) {
      if (this.$timers[this.chatId]) {
        this.$timers[this.chatId].stop()
      }
      if (time === 0) {
        return
      }
      const content = '问诊时间不足3分钟请及时续费，到时将自动关闭问诊'
      doctorChatDetails(this.chatId).then(res => {
        this.$timers[this.chatId] = new Timer(time, {
          chatId: this.chatId,
          type: 0,
          content,
          doctorId: res.hxDoctorName,
          userId: res.hxUserName,
        })
        this.$timers[this.chatId].start(()=>{})
      })
    },
    onMessageReceived(event) {
      const messageList = event.data;
      messageList.forEach((message) => {
        if (message.type === this.$TencentCloudChat.TYPES.MSG_TEXT) {
          // 文本消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.TextPayload
          console.log('收到文本消息', message);
          this.playAudio()

          this.$store.dispatch('set_vuex_update_chat', { guid: getGuid(message), ...message })
          this.unreadMessage()
        } else if (message.type === this.$TencentCloudChat.TYPES.MSG_IMAGE) {
          // 图片消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.ImagePayload
          console.log('收到图片消息', message)
        } else if (message.type === this.$TencentCloudChat.TYPES.MSG_SOUND) {
          // 音频消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.AudioPayload
          console.log('音频消息', message)
        } else if (message.type === this.$TencentCloudChat.TYPES.MSG_VIDEO) {
          // 视频消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.VideoPayload
          console.log('视频消息', message)
        } else if (message.type === this.$TencentCloudChat.TYPES.MSG_FILE) {
          // 文件消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.FilePayload
          console.log('文件消息', message)
        } else if (message.type === this.$TencentCloudChat.TYPES.MSG_CUSTOM) {
          // 自定义消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.CustomPayload
          console.log('自定义消息', message)
          console.log('message.payload', message.payload);
          // data 1 医生收到问诊 data 2  医生接诊 3 用户支付成功，更新问诊时长
          if (message.payload.data === '1') {
            this.playAudio()
            if (this.$route.name !== 'onlineConsultation') {
              this.asyncRouterMap.forEach(item => {
                if (item.name === 'onlineConsultationFather') {
                  item.meta.showBadge = true
                }
              })
            }
            this.$store.dispatch('set_vuex_update_chat_reception', { guid: getGuid(), ...message })
          } else if (message.payload.data === '3') {
            this.chatId = message.payload.description
            this.getChatTimeOut()
            this.playAudio()
            this.$store.dispatch('set_vuex_update_chat_reception', { guid: getGuid(), ...message })
          }
        } else if (message.type === this.$TencentCloudChat.TYPES.MSG_MERGER) {
          // 合并消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.MergerPayload
          console.log('合并消息', message)
        } else if (message.type === this.$TencentCloudChat.TYPES.MSG_LOCATION) {
          // 地理位置消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.LocationPayload
          console.log('位置消息', message)

        } else if (message.type === this.$TencentCloudChat.TYPES.MSG_GRP_TIP) {
          // 群提示消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.GroupTipPayload
          console.log('群提示消息', message)

        } else if (message.type === this.$TencentCloudChat.TYPES.MSG_GRP_SYS_NOTICE) {
          // 群系统通知 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.GroupSystemNoticePayload
          const { operationType, userDefinedField } = message.payload;
          console.log(' 群系统通知', message)
          // operationType - 操作类型
          // userDefinedField - 用户自定义字段（对应 operationType 为 255）
          // 使用 RestAPI 在群组中发送系统通知时，接入侧可从 userDefinedField 拿到自定义通知的内容。
        }
      });
    },
    playAudio() {
      this.$refs.audio.play().catch(error => console.error('播放失败:', error));
    },
    onKickedOut(event) {
      console.log('被踢下线', event.data.type);
      // TencentCloudChat.TYPES.KICKED_OUT_MULT_ACCOUNT(Web端，同一账号，多页面登录被踢)
      // TencentCloudChat.TYPES.KICKED_OUT_MULT_DEVICE(同一账号，多端登录被踢)
      // TencentCloudChat.TYPES.KICKED_OUT_USERSIG_EXPIRED(签名过期)
      // TencentCloudChat.TYPES.KICKED_OUT_REST_API(REST API kick 接口踢出)
      this.$store.dispatch('LogOut').then(() => {
        this.$router.replace({ path: '/login' })
      })

    },
  }
}
</script>
<style>
body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100vh;
  user-select: none;
}
</style>
<style lang="scss" scoped>
.call-container {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
