<template>
    <div class="main">
        <div class="workbench">工作台</div>
        <div class="router">
            <div v-for="item in asyncRouterMap" :key="item.name" class="item" @click="toPath(item)">
                <el-badge v-if="item.meta.showBadge" is-dot class="badge"></el-badge>
                <div class="img-box">
                    <img :src="item.meta.img" class="img" alt="">
                </div>
                <div class="title">
                    {{ item.meta.title }}
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { asyncRouterMap } from '@/router'
export default {
    data() {
        return {
            asyncRouterMap: asyncRouterMap
        }
    },
    methods: {
        toPath(item) {
            if (item.name === 'messageFather' || item.name === 'onlineConsultationFather') {
                item.meta.showBadge = false
            }
            this.$router.push({ path: item.path })
        }
    }
}
</script>
<style lang="scss" scoped>
.main {
    .workbench {
        text-align: center;
        padding: 20px 0;
    }

    .router {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        justify-items: center;
        text-align: center;

        .item {
            cursor: pointer;
            color: #000;
            position: relative;

            .badge {
                position: absolute;
                right: 0px;
                top: 0;
            }

            .img-box {
                width: 84px;
                height: 84px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #f1f1f1;
                border-radius: 4px;

                .img {
                    width: 60px;
                    height: 60px;
                }
            }
        }
    }

}
</style>