<template>
    <div>
        <el-dialog title="修改手机号" :visible.sync="dialogVisible" width="600px" :close-on-click-modal="false"
            :close-on-press-escape="false" :show-close="false">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
                <h3>请输入新的手机号码</h3>
                <div class="tips">更换手机号后，需要使用新的手机号登录。</div>
                <div class="tips" style="margin-bottom: 16px;">当前登录手机号码：{{ ruleForm.oldPhone }}</div>
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="ruleForm.phone" maxlength="11" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item label="图片验证码" prop="imgCode">
                    <el-input v-model="ruleForm.imgCode" placeholder="请输入图片验证码后再获取短信验证码">
                        <img slot="append" :src="'data:image/png;base64,' + imgCodeObj.img" @click="getImgCode"
                            style="width: 100px" mode="widthFix" />
                    </el-input>
                </el-form-item>
                <el-form-item label="短信验证码" prop="verificationCode">
                    <el-input v-model="ruleForm.verificationCode" placeholder="请输入验证码">
                        <el-button slot="append" type="primary" :disabled="isGetCode" @click="getCode">
                            {{ isGetCode ? this.countdown + "秒后重新获取" : "获取验证码" }}
                        </el-button>
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { updatePassWord, captchaImage,sendPhoneCode ,updateMedDoctorPhone } from '@/api/login'
export default {
    data() {
        return {
            dialogVisible: false,
            ruleForm: {
                oldPhone: '',
                phone: '',
                imgCode: '',
                verificationCode: '',
            },

            rules: {
                phone: [
                    { required: true, message: '请输入手机号', trigger: 'change' }
                ],
                imgCode: [
                    { required: true, message: '请输入图片验证码', trigger: 'blur' },
                    { validator: this.validateImg, trigger: 'blur' }
                ],
                verificationCode: [{ required: true, message: "请输入短信验证码", trigger: "blur" }],
            },
            imgCodeObj: {},
            countdown: 60,
            isGetCode: false,
        }
    },
    methods: {
        open(phone) {
            this.ruleForm.oldPhone = phone
            this.getImgCode()
            this.dialogVisible = true
        },
        async getImgCode() {
            this.imgCodeObj = await captchaImage();
        },
        validateImg(rule, value, callback) {
            if (value !== this.imgCodeObj.verifyCode) {
                callback('图片验证码错误')
            } else {
                callback()
            }
        },
        // 获取短信验证码
        getCode() {
            if (this.isGetCode) {
                return;
            }
            if (!this.ruleForm.phone) {
                this.$message.error("请输入手机号");
                return
            }
            if (this.ruleForm.imgCode !== this.imgCodeObj.verifyCode) {
                this.$message.error("图片验证码错误");
                return
            }
            console.log('this.ruleForm.phone', this.ruleForm.phone);
            sendPhoneCode({ phone: this.ruleForm.phone }).then((res) => {
                this.isGetCode = true;
                this.countdown = 60;
                if (this.intervalId) clearInterval(this.intervalId);
                this.intervalId = setInterval(() => {
                    if (this.countdown > 0) {
                        this.countdown--;
                    } else {
                        clearInterval(this.intervalId);
                        this.isGetCode = false;
                    }
                }, 1000);
            });

        },
        submit() {
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    const data = {
                        verificationCode: this.ruleForm.verificationCode,
                        phone: this.ruleForm.phone
                    }
                    await updateMedDoctorPhone(data)
                    this.$message.success('修改成功')
                    this.$store.dispatch('LogOut').then(() => {
                        this.$router.replace({ path: '/login' })
                    })
                    this.dialogVisible = false
                } else {
                    return false;
                }
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.tips {
    color: #666;
    font-size: 14px;
}
</style>