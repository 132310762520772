export default {
    bind(el, binding, vnode) {
      let startX, startY, currentX, currentY, offset, clientRect;
  
      function handleMouseDown(event) {
        // 获取元素的初始位置
        clientRect = el.getBoundingClientRect();
        offset = {
          x: event.clientX - clientRect.left,
          y: event.clientY - clientRect.top
        };
  
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
      }
  
      function handleMouseMove(event) {
        // 计算新的位置
        currentX = Math.min(Math.max(event.clientX - offset.x, 0), window.innerWidth - el.offsetWidth);
        currentY = Math.min(Math.max(event.clientY - offset.y, 0), window.innerHeight - el.offsetHeight);
  
        // 设置新的位置
        el.style.transform = `translate(${currentX}px, ${currentY}px)`;
      }
  
      function handleMouseUp() {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      }
  
      el.addEventListener('mousedown', handleMouseDown);
    },
  
    unbind(el) {
      el.removeEventListener('mousedown', handleMouseDown);
    }
  };