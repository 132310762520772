import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import seamlessScroll from "vue-seamless-scroll";
import "@/assets/style.css";
import * as $echarts from "echarts";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./premission.js";
import modal from "@/utils/modal";
import "@/icons/index"; //vue中使用svg
import SvgIcon from "@/components/SvgIcon"; // svg component

import { chat } from "./utils/tx.js";
import TencentCloudChat from "@tencentcloud/chat";

// 分页组件
import Pagination from "@/components/Pagination/index";
// 图片上传组件
import ImageUpload from "@/components/ImageUpload";
Vue.component("Pagination", Pagination);
Vue.component("ImageUpload", ImageUpload);
Vue.component("svg-icon", SvgIcon);

Vue.prototype.$echarts = $echarts;
Vue.prototype.$modal = modal;
// 创建一个全局对象来保存计时器状态
Vue.prototype.$timers = {};
// 全局设置chat
Vue.prototype.$chat = chat;
Vue.prototype.$TencentCloudChat = TencentCloudChat;
Vue.use(seamlessScroll);
Vue.use(ElementUI);
Vue.config.productionTip = false;

new Vue({
 router,
 store,
 render: (h) => h(App),
}).$mount("#app");
